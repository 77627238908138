import { default as indexSdyivCfnMuMeta } from "/opt/build/repo/pages/experiences/index.vue?macro=true";
import { default as _91_46_46_46slug_93kaQIBRuWnsMeta } from "/opt/build/repo/pages/hobbies/[...slug].vue?macro=true";
import { default as indexjKQsxhFtFrMeta } from "/opt/build/repo/pages/hobbies/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexxtMqaql9AYMeta } from "/opt/build/repo/pages/projects/index.vue?macro=true";
import { default as _91_46_46_46slug_93lBpTGEmPuFMeta } from "/opt/build/repo/pages/publications/[...slug].vue?macro=true";
import { default as indexnZcQi5GJTxMeta } from "/opt/build/repo/pages/publications/index.vue?macro=true";
export default [
  {
    name: indexSdyivCfnMuMeta?.name ?? "experiences___it",
    path: indexSdyivCfnMuMeta?.path ?? "/experiences",
    meta: indexSdyivCfnMuMeta || {},
    alias: indexSdyivCfnMuMeta?.alias || [],
    redirect: indexSdyivCfnMuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: indexSdyivCfnMuMeta?.name ?? "experiences___en",
    path: indexSdyivCfnMuMeta?.path ?? "/en/experiences",
    meta: indexSdyivCfnMuMeta || {},
    alias: indexSdyivCfnMuMeta?.alias || [],
    redirect: indexSdyivCfnMuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: indexSdyivCfnMuMeta?.name ?? "experiences___es",
    path: indexSdyivCfnMuMeta?.path ?? "/es/experiences",
    meta: indexSdyivCfnMuMeta || {},
    alias: indexSdyivCfnMuMeta?.alias || [],
    redirect: indexSdyivCfnMuMeta?.redirect,
    component: () => import("/opt/build/repo/pages/experiences/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kaQIBRuWnsMeta?.name ?? "hobbies-slug___it",
    path: _91_46_46_46slug_93kaQIBRuWnsMeta?.path ?? "/hobbies/:slug(.*)*",
    meta: _91_46_46_46slug_93kaQIBRuWnsMeta || {},
    alias: _91_46_46_46slug_93kaQIBRuWnsMeta?.alias || [],
    redirect: _91_46_46_46slug_93kaQIBRuWnsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hobbies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kaQIBRuWnsMeta?.name ?? "hobbies-slug___en",
    path: _91_46_46_46slug_93kaQIBRuWnsMeta?.path ?? "/en/hobbies/:slug(.*)*",
    meta: _91_46_46_46slug_93kaQIBRuWnsMeta || {},
    alias: _91_46_46_46slug_93kaQIBRuWnsMeta?.alias || [],
    redirect: _91_46_46_46slug_93kaQIBRuWnsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hobbies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93kaQIBRuWnsMeta?.name ?? "hobbies-slug___es",
    path: _91_46_46_46slug_93kaQIBRuWnsMeta?.path ?? "/es/hobbies/:slug(.*)*",
    meta: _91_46_46_46slug_93kaQIBRuWnsMeta || {},
    alias: _91_46_46_46slug_93kaQIBRuWnsMeta?.alias || [],
    redirect: _91_46_46_46slug_93kaQIBRuWnsMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hobbies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexjKQsxhFtFrMeta?.name ?? "hobbies___it",
    path: indexjKQsxhFtFrMeta?.path ?? "/hobbies",
    meta: indexjKQsxhFtFrMeta || {},
    alias: indexjKQsxhFtFrMeta?.alias || [],
    redirect: indexjKQsxhFtFrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hobbies/index.vue").then(m => m.default || m)
  },
  {
    name: indexjKQsxhFtFrMeta?.name ?? "hobbies___en",
    path: indexjKQsxhFtFrMeta?.path ?? "/en/hobbies",
    meta: indexjKQsxhFtFrMeta || {},
    alias: indexjKQsxhFtFrMeta?.alias || [],
    redirect: indexjKQsxhFtFrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hobbies/index.vue").then(m => m.default || m)
  },
  {
    name: indexjKQsxhFtFrMeta?.name ?? "hobbies___es",
    path: indexjKQsxhFtFrMeta?.path ?? "/es/hobbies",
    meta: indexjKQsxhFtFrMeta || {},
    alias: indexjKQsxhFtFrMeta?.alias || [],
    redirect: indexjKQsxhFtFrMeta?.redirect,
    component: () => import("/opt/build/repo/pages/hobbies/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___it",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/en",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___es",
    path: indexlaFeZuYZkUMeta?.path ?? "/es",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects___it",
    path: indexxtMqaql9AYMeta?.path ?? "/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects___en",
    path: indexxtMqaql9AYMeta?.path ?? "/en/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexxtMqaql9AYMeta?.name ?? "projects___es",
    path: indexxtMqaql9AYMeta?.path ?? "/es/projects",
    meta: indexxtMqaql9AYMeta || {},
    alias: indexxtMqaql9AYMeta?.alias || [],
    redirect: indexxtMqaql9AYMeta?.redirect,
    component: () => import("/opt/build/repo/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93lBpTGEmPuFMeta?.name ?? "publications-slug___it",
    path: _91_46_46_46slug_93lBpTGEmPuFMeta?.path ?? "/publications/:slug(.*)*",
    meta: _91_46_46_46slug_93lBpTGEmPuFMeta || {},
    alias: _91_46_46_46slug_93lBpTGEmPuFMeta?.alias || [],
    redirect: _91_46_46_46slug_93lBpTGEmPuFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/publications/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93lBpTGEmPuFMeta?.name ?? "publications-slug___en",
    path: _91_46_46_46slug_93lBpTGEmPuFMeta?.path ?? "/en/publications/:slug(.*)*",
    meta: _91_46_46_46slug_93lBpTGEmPuFMeta || {},
    alias: _91_46_46_46slug_93lBpTGEmPuFMeta?.alias || [],
    redirect: _91_46_46_46slug_93lBpTGEmPuFMeta?.redirect,
    component: () => import("/opt/build/repo/pages/publications/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexnZcQi5GJTxMeta?.name ?? "publications___it",
    path: indexnZcQi5GJTxMeta?.path ?? "/publications",
    meta: indexnZcQi5GJTxMeta || {},
    alias: indexnZcQi5GJTxMeta?.alias || [],
    redirect: indexnZcQi5GJTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/publications/index.vue").then(m => m.default || m)
  },
  {
    name: indexnZcQi5GJTxMeta?.name ?? "publications___en",
    path: indexnZcQi5GJTxMeta?.path ?? "/en/publications",
    meta: indexnZcQi5GJTxMeta || {},
    alias: indexnZcQi5GJTxMeta?.alias || [],
    redirect: indexnZcQi5GJTxMeta?.redirect,
    component: () => import("/opt/build/repo/pages/publications/index.vue").then(m => m.default || m)
  }
]