
import * as storyblokRuntime$Mamms8fOAd from '/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/providers/storyblok'
import * as ipxStaticRuntime$CH1qK1hjCW from '/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$Mamms8fOAd, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['ipxStatic']: { provider: ipxStaticRuntime$CH1qK1hjCW, defaults: {} }
}
        